import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GroupService } from './services/group.service';
import { SocketService } from './services/socket.service';
import { Constants } from './constants/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PushNotificationComponent } from './ui/push-notification/push-notification.component';
import { AuthenticationService } from './services/authentication.service';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { NotificationService } from './services/notification.service';
import { ChatService } from './services/chat.service';
import 'quill-emoji/dist/quill-emoji.js';
import { NavigationEnd, NavigationStart, Router, NavigationCancel } from '@angular/router';
import { PricingService } from './services/pricing.service';
import { StorageService } from './services/storage.service';
import { Utils } from './utils';
import { Meta, Title } from '@angular/platform-browser';
import { DashboardService } from './services/dashboard.service';
import { IllustrationService } from './services/illustration.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  public showScrolling = false;
  public currentScrollingPage = '';
  public currentUrl = '';

  private updateChatSubscriber: Subscription;
  private groupChatSubscriber: Subscription;
  private memberChatSubscriber: Subscription;
  private showScrollViewSubscriber: Subscription;
  private socketClientResearchSubscriber: Subscription;

  public dashboardService: DashboardService;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    public groupService: GroupService,
    public socketService: SocketService,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private chatService: ChatService,
    private router: Router,
    private pricingService: PricingService,
    private storageService: StorageService,
    private titleService: Title,
    private meta: Meta,
    private authenticationService: AuthenticationService,
    private illustrationService: IllustrationService
  ) {
    this._document.getElementById('appFavicon').setAttribute('href', environment.favIcon);
    this.titleService.setTitle(`${environment.title === 'Rgrid' ? 'TrialEngine' : environment.title }`);
    this.meta.addTags([
      { name: 'application-name', selector: environment.title === 'Rgrid' ? 'TrialEngine' : environment.title },
      { name: 'apple-mobile-web-app-title', selector: environment.title === 'Rgrid' ? 'TrialEngine' : environment.title },
      { name: 'og:title', selector: environment.title === 'Rgrid' ? 'TrialEngine' : environment.title },
      { name: 'og:type', selector: 'website' },
    ]);
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationStart) {
        Utils.blockPage();
      } else if (val instanceof NavigationEnd) {
        Utils.unblockPage();
        this.currentUrl = val.urlAfterRedirects || val.url;
      } else if (val instanceof NavigationCancel) {
        Utils.unblockPage();
      }
    });
    this.showScrollViewSubscriber = this.storageService.updateScrollView.subscribe(res => {
      if (Object.keys(res).length) {
        this.showScrolling = true;
        this.currentScrollingPage = res.page;
      }
    });
    this.authenticationService.authenticationStatusUpdate.subscribe(res => {
      if (this.authenticationService.isLoggedIn) {
        this.initiliseAllConnections();
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.authenticationService.isLoggedIn) {
      this.initiliseAllConnections();
    }
  }

  /**
   * Run only when the user gets logged in
   */
  private initiliseAllConnections(): void {
    this.getUserOnlineStatus();
    this.listenGroupMessages();
    this.listenMemberChats();
    this.listenRaisedReqeustToJoin();
    this.listenRaisedUpdateRequestToJoin();
    this.listenGroupDelete();
    this.listenProjectShare();
    this.listenTraining();
    this.listenCostComment();
    this.listenToClientChats();
  }

  private listenToClientChats() {
    this.socketClientResearchSubscriber = this.socketService.listenToClientResearcher().subscribe(res => {
      this.chatService.showNotification({ name: res.displayName }, 'client-chat', [res]);
    });
  }
  /**
   * Function used to show the push notification and the update the notification count for the member section.
   * The function will be rendered only if the chat component is rendered.
   */
  private listenMemberChats() {
    this.memberChatSubscriber = this.socketService.updatedMemberChats().subscribe((chats) => {
      if (!this.chatService.isGroupChatComponentRendered) {
        this.chatService.showNotification('', 'member-chat', chats);
      }
    }, (err) => {
      console.error('Error in socket ', err);
    });
  }

  /**
   * Function used to show th push notification and update the notification count for the group section.
   * The function will be run only if the group-chat is not rendered.
   */
  private listenGroupMessages() {
    this.groupChatSubscriber = this.socketService.updatedGroupChats().subscribe((chats) => {
      if (!this.chatService.isGroupChatComponentRendered) {

        this.chatService.showNotification({ name: chats[Constants.ZERO].groupName }, 'Group', chats);
      }
    }, (err) => {
      console.error('Error in socket ', err);
    });
  }

  /**
   * Function is used to listen the user's online/offline status.
   * The function will be subscribed when the user's status has been updated in the server.
   */
  private getUserOnlineStatus() {
    this.socketService.updateUserOnlineStatus().subscribe(res => {
      if (res) {
        this.socketService.updateUserActiveStatus(res);
      }
    }, (err) => console.error('Error on connecting the socket ', err));
  }


  /**
   * Listening to the socket connection to get the raised new request either in the groups / contacts
   */
  private listenRaisedReqeustToJoin() {
    this.socketService.listenRaisedRequest().subscribe((res: { requestType: any; }) => {
      this.snackBar.openFromComponent(PushNotificationComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: {
          notification: res,
          type: res.requestType
        }
      });
      this.groupService.sendMsgCount();
    });
  }

  /**
   * Function listens the socket emitter of the approved raised request
   */
  private listenRaisedUpdateRequestToJoin() {
    this.socketService.listenRaisedUpdateRequest().subscribe(res => {
      this.snackBar.openFromComponent(PushNotificationComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: {
          notification: {
            message: `Admin of the group ${res.groupName}, ${res.requestStatus} your request.`
          },
          type: 'group-update'
        }
      });
      this.groupService.sendMsgCount();
    });
  }

  /**
   * Function listens to the group delete and updates the live reflection
   */
  private listenGroupDelete() {
    this.socketService.listenGroupDelete().subscribe(message => {
      this.snackBar.openFromComponent(PushNotificationComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: {
          notification: {
            message,
          },
          type: 'group-delete'
        }
      });
      this.groupService.sendMsgCount();
    });
  }

  /**
   * Function used to listen the project share status to show the push notifications.
   */
  private listenProjectShare() {
    this.socketService.listenProjectShare().subscribe(({ message, projectId }) => {
      const currentProjectID = this.storageService.getProjectId();
      if (this.currentUrl.indexOf('/dashboard/project/') > -1 && projectId === currentProjectID) {
        window.location.reload();
      } else if (this.currentUrl.indexOf('/dashboard/projects') > -1) {
        this.notificationService.showNotification({
          notification: {
            message
          },
          type: 'project-share'
        });
      }
    });
  }

  /**
   * Function used to listen the project share status to show the push notifications.
   */
  private listenTraining() {
    this.socketService.listenTraining().subscribe(message => {
      this.notificationService.showNotification({
        notification: {
          ...message
        },
        type: 'training'
      });
    });
  }

  /**
   * Listens the cost comments.
   */
  private listenCostComment() {
    this.socketService.listenCostComments().subscribe(res => {
      if (res.notificationType === 'create') {
        this.notificationService.showNotification({
          notification: res,
          type: 'Cost-comment'
        });
      }
    });
  }

  /**
   * Function triggers from HTML
   * Checks and updates the scroll view pages.
   * @param event
   */
  public updateGotItStatus(event) {
    event.stopPropagation();
    this.showScrolling = false;
    this.storageService.updateScrollViewStatus.next({ page: this.currentScrollingPage, status: true });
    const scrollViewElements = this.storageService.getSessionStorage('scrollView');
    if (!scrollViewElements) {
      this.storageService.setSessionStorage('scrollView', JSON.stringify([this.currentScrollingPage]));
    } else {
      const viewElements = JSON.parse(scrollViewElements);
      this.storageService.setSessionStorage('scrollView', JSON.stringify([...viewElements, this.currentScrollingPage]));
    }
  }

  ngOnDestroy() {
    this.updateChatSubscriber ? this.updateChatSubscriber.unsubscribe() : null;
    this.groupChatSubscriber ? this.groupChatSubscriber.unsubscribe() : null;
    this.memberChatSubscriber ? this.memberChatSubscriber.unsubscribe() : null;
    this.showScrollViewSubscriber ? this.showScrollViewSubscriber.unsubscribe() : null;
    this.socketClientResearchSubscriber ? this.socketClientResearchSubscriber.unsubscribe() : null;
  }

  public getInProjectAssets(path: string): string {
    return this.illustrationService.getInProjectAssets(path);
  }
}
