/*
  * This service is used to update the project preview and
    get the profile picture.
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root'
})

export class NavbarService {
  // Behavior subject used to handle the nav bar content
  public hideNavBarContent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public updateLogo: BehaviorSubject<any> = new BehaviorSubject<object>({});

  public getUserNotification: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);


  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) { }

  getProfilePicture(userId: string) {
    return this.http.get(`${environment.baseUrl}api/user/profilePath?userId=${userId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  updateProjectPreview(data: object) {
    return this.http.put(`${environment.baseUrl}api/user-project/preview`, data).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  handleResponse(response: any) {
    return response;
  }
}
