/*
  * This service is used to get and update the notifications of a
  *  particular user.
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { PushNotificationComponent } from '../ui/push-notification/push-notification.component';
import { StorageService } from './storage.service';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  public notificationUpdate = new Subject<{}>();

  constructor(private http: HttpClient, private snackBar: MatSnackBar,
              private handleErrorService: HandleErrorService, private storageService: StorageService) { }

  getNotificationsForUser(userId: string) {
    return this.http.get(`${environment.baseUrl}api/notification/?userId=${userId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  updateNotificationByIds(notificationIds: string) {
    return this.http.put(`${environment.baseUrl}api/notification/`, { notificationIds }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  /**
   * API function used to create the notification in DB after showing the push notification
   */
  createNotification(notificationDetails) {
    return this.http.post(`${environment.baseUrl}api/notification/`, { ...notificationDetails }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  /**
   * API used to delete the notification for the user
   */
  deleteNotification(id: string): Promise<any> {
    try {
      return this.http.delete(`${environment.baseUrl}api/notification/delete`, { params: { id } }).toPromise();
    } catch (err) {
      return this.handleErrorService.handleError(err);
    }
  }

  /**
   * API used to mark all user notifications as read.
   */
  markNotificationsRead(): Promise<any> {
    try {
      return this.http.post(`${environment.baseUrl}api/notification/mark-read`, { }).toPromise();
    } catch (err) {
      return this.handleErrorService.handleError(err);
    }
  }

  /**
   * API used to update the notification read count for the user
   */
  updateReadNotificationCount() {
    return this.http.put(`${environment.baseUrl}api/notification/count`, {}).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }


  // Shows the push notification for the user
  showNotification(dataParams) {
    const userId = this.storageService.getUserId();
    const token = this.storageService.getKeyValue('token');
    if (token && userId) {
      this.snackBar.openFromComponent(PushNotificationComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: dataParams
      });
    }
  }

  getNotificationRoute(shareType) {
    let route = '';
    switch (shareType) {
      case 'SingleDocs':
        route = 'document';
        break;
      case 'BulkDocs':
        route = 'document/bulk';
        break;
      case 'summarySingle':
        route = 'summary';
        break;
      case 'summaryBulk':
        route = 'summary/bulk';
        break;
      case 'engage':
        route = 'engage';
        break;
      case 'articleInsight':
        route = 'insights';
        break;
      case 'request insights':
        route = 'insights/request';
        break;
      case 'trends':
        route = 'trends';
        break;
      case 'costs':
        route = 'costs';
        break;
      default:
        if (shareType.indexOf('cost') > -1) {
          const commentType = shareType.split('_')[1];
          route = `costs?type=${commentType}`;
          break;
        }
        route = 'document';
    }
    return route;
  }

  handleResponse(response: any) {
    return response;
  }
}
