import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, first, filter } from 'rxjs/operators';
import { Constants } from './../constants/constants';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  public readonly store: Record<string, Observable<HttpEvent<any>>> = {};

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Don't cache if it's not cacheable
    if (req.method !== 'GET') {

      // Do not cache PUT, POST, DELETE and PATCH
      return next.handle(req);
    } else if ((Constants.CACHE_API.indexOf(req.url) !== -1) || (req.url.indexOf('/assets/') !== -1)) {

      // Cache selective GET APIs and assets.
      const cachedObservable = this.store[req.urlWithParams] || (this.store[req.urlWithParams] = next.handle(req).pipe(filter((res) => res instanceof HttpResponse), shareReplay(1)));
      return cachedObservable.pipe(first());
    } else {
      return next.handle(req);
    }
  }
}
