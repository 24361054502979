/*
  * This service is used to updated votes.
  * And also for the purpose of group-chat to be live
*/

import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable, Subject } from 'rxjs';
import { sortBy } from 'lodash';

import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  // listenToClientResearcher() {
  //   throw new Error('Method not implemented.');
  // }
  // sendMsgToClientResearcher(arg0: { id: any; message: string; createdAt: Date; userId: any; chatId: any; displayName: string; profilePic: string; hasAttachment: boolean; file: any; fileName: any; signedUrl: any; linkPreview: any; }) {
  //   throw new Error('Method not implemented.');
  // }

  private socket;
  private sendMsgToSlideBar = new Subject<any>();
  private sendChatToTable = new Subject<any>();
  private sendChatToMembersTable = new Subject<any>();
  private sendUserActiveStatus = new Subject<any>();

  updateVoteToPresentation$ = this.sendMsgToSlideBar.asObservable();
  updateGroupChats = this.sendChatToTable.asObservable();
  updateForumChats = this.sendChatToTable.asObservable();
  updateMemberChats = this.sendChatToMembersTable.asObservable();
  userActiveStatus$ = this.sendUserActiveStatus.asObservable();

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.socket = io(environment.socketConnectionUrl, {
      transports: ['websocket']
    });
    if (this.authenticationService.isLoggedIn) {
      this.setUserToOnline(this.authenticationService.user);
    }
  }

  public get userInstantNotifications(): Observable<any> {
    return Observable.create((observer) => {
      this.socket.on(this.authenticationService.user, (requestData) => {
        observer.next(requestData);
      });
    });
  }

  public emitTrainingNotification = (training) => {
    this.socket.emit('training', training);
  }

  public listenDuplicateProject = () => {
    return Observable.create((observer) => {
      this.socket.on(`duplicate-project`, (message) => {
        observer.next(message);
      });
    });
  }
  public listenTraining = () => {
    return Observable.create((observer) => {
      this.socket.on(`training`, (message) => {
        observer.next(message);
      });
    });
  }
  public setUserToOnline = (userId) => {
    const envTitle = environment.title;
    this.socket.emit('user_connect', { userId, environment: envTitle === 'Rgrid' ? 'R.grid' : 'Inclusive' });
    // this.socket.emit('user_connect', userId);
  }

  public setUserToOffline = (userId) => {
    this.socket.emit('user_disconnect', userId);
  }

  public sendMsgToUpdateVote = (slide) => {
    const envTitle = environment.title;
    this.socket.emit(`update_question`, { ...slide, environment: envTitle === 'Rgrid' ? 'R.grid' : 'Inclusive' });
  }

  public setInitailOnlineStatus = (users) => {
    this.socket.emit('iniatialStatus', users);
  }

  public sendMsgToOthers = (chats) => {
    this.socket.emit(`update_chats`, chats);
  }

  public sendMsgToMember = (chats) => {
    this.socket.emit(`update_member_chats`, chats);
  }

  // Send socket message to server upon deleting the chat
  public sendDeletedMessage = (chat) => {
    this.socket.emit(`userDeletesGroupChat`, chat);
  }

  public sendGroupStatus = () => {
    this.socket.emit(`update_groups`);
  }

  public sendDeletedForumMessage = (chat) => {
    this.socket.emit(`userDeletesForumChat`, chat);
  }

  public sendForumStatus = () => {
    this.socket.emit(`update_forums`);
  }

  public updateDocContentMonitor = (data) => {
    this.socket.emit(`doc_edit_monitor`, data);
  }

  public intimateGroupDelete = (groupMembers) => {
    this.socket.emit('groupDeleteNotify', groupMembers);
  }

  public intimateForumDelete = (forumMembers) => {
    this.socket.emit('forumDeleteNotify', forumMembers);
  }

  // Emits the project share messages to the shared users
  public emitProjectShare = (sharedMembers) => {
    this.socket.emit('notifyProjectShare', sharedMembers);
  }

  // Emits when the user removes the access for the project
  public emitProjectShareRemoveAccess = (member) => {
    this.socket.emit('notifyProjectRemoveAccess', member);
  }

  public sendMsgToClientResearcher = (chat) => {
    this.socket.emit(`update_client_chats`, chat);
  }

  public listenToClientResearcher = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_client_chats`, (chat) => {
        observer.next(chat);
      });
    });
  }

  public listenGroupDelete = () => {
    return Observable.create((observer) => {
      this.socket.on(`groupDeleteNotifyToUser`, (message) => {
        observer.next(message);
      });
    });
  }

  public listenForumDelete = () => {
    return Observable.create((observer) => {
      this.socket.on(`forumDeleteNotifyToUser`, (message) => {
        observer.next(message);
      });
    });
  }

  public listenSingleDocUpdate = () => {
    return Observable.create((observer) => {
      this.socket.on(`updated_single_doc`, (doc) => {
        observer.next(doc);
      });
    });
  }

  public listenBulkDocUpdate = () => {
    return Observable.create((observer) => {
      this.socket.on(`updated_bulk_doc`, (doc) => {
        observer.next(doc);
      });
    });
  }

  public listenEngageDocUpdate = () => {
    return Observable.create((observer) => {
      this.socket.on(`updated_engage`, (doc) => {
        observer.next(doc);
      });
    });
  }

  public sendEditRequest = (data) => {
    this.socket.emit(`getRequestToEdit`, data);
  }

  public givePermissionToEdit = (data) => {
    this.socket.emit(`givePermissionToEdit`, data);
  }


  public sendRequestToGetEditStatus = (data) => {
    this.socket.emit('sendRequestToGetEditStatus', data);
  }

  public sendRaisedRequest = (data) => {
    this.socket.emit('userRequestedToJoin', data);
  }

  public sendUpdateOfRasiedRequest = (data) => {
    this.socket.emit('updateUserRequestedToJoin', data);
  }

  public sendCostCommentNotification = (costComment) => {
    this.socket.emit('costCommentnotfication', costComment);
  }

  public listenRaisedRequest = () => {
    return Observable.create((observer) => {
      this.socket.on(`userRequestToJoin`, (requestData) => {
        observer.next(requestData);
      });
    });
  }

  public listenRaisedUpdateRequest = () => {
    return Observable.create((observer) => {
      this.socket.on(`updateUserRequestToJoin`, (requestData) => {
        observer.next(requestData);
      });
    });
  }

  public listenAllowToEdit = () => {
    return Observable.create((observer) => {
      this.socket.on(`allow_to_edit`, (docId) => {
        observer.next(docId);
      });
    });
  }

  public enableEdit = () => {
    return Observable.create((observer) => {
      this.socket.on(`enableEdit`, (docId) => {
        observer.next(docId);
      });
    });
  }

  public updatedVotes = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_question`, (slide) => {
        observer.next(slide);
      });
    });
  }

  public updatedGroupChats = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_chats`, (chats) => {
        observer.next(sortBy(chats, 'createdAt'));
      });
    });
  }

  public updatedForumChats = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_chats`, (chats) => {
        observer.next(sortBy(chats, 'createdAt'));
      });
    });
  }

  public updateUserOnlineStatus = () => {
    return Observable.create((observer) => {
      this.socket.on('online_status', (status) => {
        observer.next(status);
      });
    });
  }

  // Socket service to update member chats
  public updatedMemberChats = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_member_chats`, (chats) => {
        observer.next(sortBy(chats, 'createdAt'));
      });
    });
  }

  public updatedGroups = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_groups`, () => {
        observer.next();
      });
    });
  }

  public updatedForums = () => {
    return Observable.create((observer) => {
      this.socket.on(`update_forums`, () => {
        observer.next();
      });
    });
  }

  // Listens the group chat delete
  public listenGroupChatDelete = () => {
    return Observable.create((observer) => {
      this.socket.on(`groupChatDelete`, (chat) => {
        observer.next(chat);
      });
    });
  }

  // Listens the forum chat delete
  public listenForumChatDelete = () => {
    return Observable.create((observer) => {
      this.socket.on(`forumChatDelete`, (chat) => {
        observer.next(chat);
      });
    });
  }

  // Listens the Cost Comments
  public listenCostComments = () => {
    return Observable.create((observer) => {
      this.socket.on(`costCommentnotfication`, (comment) => {
        observer.next(comment);
      });
    });
  }


  // Listens the project share status of the user
  public listenProjectShare = () => {
    return Observable.create((observer) => {
      this.socket.on(`notifyProjectShare`, (message) => {
        observer.next(message);
      });
    });
  }
  updateVoteToPresentation(slide) {
    this.sendMsgToSlideBar.next(slide);
  }

  updateChat(chats) {
    this.sendChatToTable.next(chats);
  }

  updateMemberChat(chats) {
    this.sendChatToMembersTable.next(chats);
  }

  updateUserActiveStatus(userOnlineStatus) {
    this.sendUserActiveStatus.next(userOnlineStatus);
  }
}
