import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { GroupService } from '../../services/group.service';

import { environment } from '../../../environments/environment';
import { NotificationService } from '../../services/notification.service';
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.css']
})
export class PushNotificationComponent implements OnInit {

  public logo = environment.DOMAIN_URL === 'inclusive.health' ? 'inclusive_health/inclusive_health_small_logo.svg' : 'small logo.svg';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public groupService: GroupService,
    public notificationService: NotificationService
  ) { }

  ngOnInit() {

    /**
     * Functionality used to create the notification and update the notification count for the user.
     * Stores the message notification only on the user's not in the chat page
     */
    if ((this.data.type === Constants.CHAT_TYPE.MEMBER || this.data.type === Constants.CHAT_TYPE.GROUP) && !this.data.skipNotificationCreate) {
      this.groupService.sendMsgCount();
      // this.notificationService.createNotification({ ...this.data.notification, type: this.data.type }).subscribe((res) => {
      //   this.groupService.sendMsgCount();
      // }, () => { });
    } else {
      this.groupService.sendMsgCount();
    }
  }
}
