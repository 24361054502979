import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { JoyrideModule } from 'ngx-joyride';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { MomentModule } from 'ngx-moment';
import highmaps from 'highcharts/modules/map.src';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { ChartsModule } from 'ng2-charts';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HeaderCacheInterceptor } from './interceptors/header-cache.interceptor';
import { AppComponent } from './app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ServerErrorComponent } from './server-error/server-error.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { CacheInterceptor } from './interceptors/cache.interceptor';

const routes: Routes = [
  {
    path: '500',
    component: ServerErrorComponent
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  }
];

export function highchartsModule() {
  return [highmaps];
}
@NgModule({
  declarations: [
    AppComponent,
    ServerErrorComponent
  ],
  imports: [
    NgxStripeModule.forRoot(environment.stripe.gbp),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsCode),
    NgxGoogleAnalyticsRouterModule.forRoot(),
    JoyrideModule.forRoot(),
    NgxChartsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, environment.firebaseConfig.projectId),
    AngularFireDatabaseModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ChartsModule,
    CookieModule.forRoot(),
    MatButtonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModule }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
