import { environment } from 'src/environments/environment';

export class Constants {
  public static get CACHE_API(): Array<string> {
    return [
      `api/optimise/landing?languageCode=en&key=landing`,
      `api/optimise/landing?languageCode=en&key=partner`,
      `api/optimise/landing?languageCode=en&key=solutions`,
      `api/optimise/patient?languageCode=en`,
      `api/optimise/landing?languageCode=en&key=consultancy`,
      `api/optimise`,
      'api/main-page/colorcodes'
      // -TODO- Reactive on Rgrid 2.0 launch
      // 'api/document/types',
      // 'api/document/types/filter',
      // 'api/question-polls/types',
      // 'api/user/roles',
      // 'api/collaboration/roles'
    ].map(x => `${environment.baseUrl}${x}`);
  }
  public static get YOUTUBE_PATTERN(): any {
    return /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
  }
  public static get EMAIL_PATTERN(): any {
    return /^[^\s()<>@,;:\/]+@\w[\w\.-]+\.[a-z]{2,}$/i;
  }
  public static get PASSWORD_PATTERN(): any {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{8,}$/;
  }
  public static get PASSWORD_NEW_PATTERN(): any {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/;
  }
  public static get LOWER_SCALE_PATTERN(): any {
    return /^[1-9]$/;
  }
  public static get HIGHER_SCALE_PATTERN(): any {
    return /^([2-9]|10)$/;
  }
  public static get POLLING_CODE_PATTERN(): any {
    return /[^0-9]+/g;
  }
  public static get PHONE_NUMBER_PATTERN(): any {
    // tslint:disable-next-line: max-line-length
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  }
  public static DATE_FORMAT = 'YYYY_MM_DD_HH_mm_ss';
  public static EMPTY_STRING = '';
  public static MINUS_ONE = -1;
  public static ZERO = 0;
  public static POINT_FIVE = 0.5;
  public static ONE = 1;
  public static TWO = 2;
  public static THREE = 3;
  public static FOUR = 4;
  public static FIVE = 5;
  public static SIX = 6;
  public static HUNDRED = 100;
  public static SNACKBAR_TIMEOUT = 3000;
  public static LAPTOP_MINWIDTH = 1300;
  public static TAB_MINWIDTH = 560;
  public static MOBILE_MAXWIDTH = 375;
  public static TRUE = true;
  public static FALSE = false;
  public static CHROME = 'Chrome';
  public static TABLE_NOT_FOUND = 'Table Not found';
  public static RESEARCHER = 'Researcher';
  public static PARTICIPANT = 'Participant';
  public static SHOW_ALL = 'Show All';
  public static choiceCategories = ['barChart', 'pieChart', 'scales', 'imageChoice',
    'horizontalChart', 'lineChart', 'imageXBarChart'];
  public static DELETE_TYPE = {
    SINGLE: 'single-delete',
    BULK: 'bulk-delete'
  };
  public static PROJECT_TABS = {
    ENGAGE: 'engage',
    REPORTS: 'reports',
    ANALYZE: 'analyze',
    SINGLE_DOC: 'single-document',
    BULK_DOC: 'bulk-document',
    SUMMARY: 'summary',
    ARTICLE_INSIGHT: 'articleInsight',
    PROTOCOL: 'protocolBuilder'
  };
  public static CHAT_TYPE = {
    MEMBER: 'member-chat',
    GROUP: 'group-chat',
    GROUP_JOIN: 'group-join',
    GROUP_DELETE: 'group-delete',
    FORUM: 'forum-chat',
    FORUM_JOIN: 'forum-join',
    FORUM_DELETE: 'forum-delete',
    PROJECT_SHARE: 'project-share'
  };
  public static ENGAGE_TYPE = {
    BAR_CHART: 'barChart',
    HORIZON_CHART: 'horizontalChart',
    SCALES: 'scales',
    PIE_CHART: 'pieChart',
    INTERVIEW: 'interview',
    OPEN_ENDED: 'openEnded',
    LINE_CHART: 'lineChart',
    GAMES: 'games',
    GAME_ONE: 'game1',
    GAME_TWO: 'game2',
    GAME_THREE: 'game3'
  };
  public static MESSAGE = {
    INVALID_CREDENTIALS: 'Invalid Credentials. Please try again!',
    SESSION_EXPIRED: 'Your session is expired. Please login again',
    TOKEN_INVALID: 'Token is not valid',
    CLIENT_NO_ACCESS: 'Please contact the researcher for access!',
    UPGRADE_PLAN: 'Your current plan does not offer this feature. Please upgrade your plan for access.'
  };
  public static TOASTER_MESSAGE = {
    ERROR: 'Server Error! Please try again later.',
    PDF_ERROR: 'Please check that your PDF has a table within it, and that the table cells have borders on all sides',
    NO_TABLES: 'No tables to extract in the PDF',
    NO_PAGE_SELECTED: 'Please select the page number',
    EXTRACT_SUCCESS: 'Extracted successfully',
    DELETE_DOC_SUCCESS: 'Your document is deleted successfully',
    DELETE_SUCCESS: 'Deleted successfully',
    SLIDE_UPDATE_NUM_SUCCESS: 'Slide Number Updated Successfully',
    MEMBER_REMOVE_SUCCESS: 'Member removed successfully!',
    PROVIDE_QUESTION: 'Please provide the question and atleast two options for the selected slide to present',
    STORE_DOC_SUCCESS: 'Document Stored Successfully',
    DUPLICATE_PROJECT_SUCCESS: 'Project is duplicated successfully',
    NOT_VALID_USER_ID: 'UserId is not valid'
  };
  public static STATUS_CODES = {
    INTERNAL_SERVER: 500,
    NOT_FOUND: 404
  };


  public static PREDICTIVE_COLORS = [{ color: '#20BF55' }, { color: '#FF8B00' }, { color: '#1A355E' }, { color: '#FFE200' }];
  public static NO_OF_VOTES = 'noOfVotes';



  public static CK_EDITOR_CONFIG = {
    allowedContent: false,
    forcePasteAsPlainText: false,
    heigth: '50%',
    removePlugins: 'horizontalrule,tabletools,specialchar,about,others, link',
    format_tags: 'p;h1;h2;h3;pre;div',
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'insert', groups: ['insert'] },
    ],
    // tslint:disable-next-line: max-line-length
    removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Outdent,Indent,CreateDiv,Blockquote,BidiLtr,BidiRtl,Language,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About'
  };

  // tslint:disable-next-line: max-line-length
  public static PASSWORD_PATTERN_ERROR_MSG = 'Password must contain at least 12 characters, including at least one uppercase, one lowercase, a number and a special character in (@$!%*?&-_).';

  public static S3PUBLIC_DOMAIN = 'https://dto7qey9evdsv.cloudfront.net/';

  public static MHRA_SIGN_MESSAGES = {
    TRAINING: {
      // tslint:disable-next-line: max-line-length
      REASON: 'Please enter the reason for signing the certificate. (For example Completion of training) This will appear at the bottom of the certificate.',
      // tslint:disable-next-line: max-line-length
      SIGN: 'Please draw your signature below to sign the document. (Tip: Press down on your mouse or trackpad to draw your signature.)'
    },
    DOCUMENT: {
      REASON: 'Please enter the reason for signing the document',
      // tslint:disable-next-line: max-line-length
      SIGN: 'Please draw your signature below to sign the document. (Tip: Press down on your mouse or trackpad to draw your signature.)'
    }
  };

  public static GOOGLE_LOGIN_SCOPE = 'profile email https://www.googleapis.com/auth/drive.file';

  public static INTEGRATION_GOOGLE_SCOPE = {
    DRIVE: 'profile email https://www.googleapis.com/auth/drive.file',
    CALENDAR: 'profile email https://www.googleapis.com/auth/calendar'
  };

  public static get UUID_PATTERN(): any {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  }

  public static DESIGN_TYPES = {
    STARTER: 2,
    ENTERPRISE: 3
  };

  public static get CLIENT_MODULES(): any {
    return {
      FULL_SYSTEM: 'Share the full Inclusive system and its capacities with your client.',
      DASHBOARD: 'Allow your client to manage their projects using a fully customizable dashboard that offers an overview of and quick access to project elements.',
      CREATE_NEW_DASHBOARD: 'Enable your client to create new dashboards for different projects.',
      SHARE_EXISTING_DASHBOARD: 'Share the dashboards you have already created.',
      PARTICIPANT_MANAGEMENT: 'Allow your client to communicate and build relationships with participants using our specialized participant management features.',
      COMMUNICATIONS: 'Help your client connect with participants and communities using our individual and group communication tools.',
      FORUMS: 'Enable access to public forums where participants can share their experiences and connect.',
      CREATE_NEW_FORUM: 'Enable your client to create new forums.',
      SHARE_EXISTING_FORUM: 'Share the forums you have already created.',
      GROUP_DISCUSSIONS: 'Allow your client to communicate with stakeholders using private, public, and anonymized group chats.',
      CREATE_NEW_GROUP: 'Enable your client to create new group discussions.',
      SHARE_EXISTING_GROUP: 'Share the group discussions you have already created.',
      CONTACT_LISTS: 'Streamline your client’s individual and group communication with personal and mass messaging.',
      CREATE_NEW_CONTACT: 'Enable your client to add new contacts.',
      COMMUNITY_RELATIONSHIPS: 'Allow your client to manage, and track patient advocacy and community group partnerships.',
      BUILD_NEW_RELATIONSHIP: 'Enable your client to create new community relationships.',
      SHARE_EXISTING_RELATIONSHIP: 'Share the community relationships you have already created.',
      PROJECT_MANAGEMENT: 'Allow your client to use Inclusive to manage their project documents and finances as well as share training materials and events.',
      PROJECTS: 'Help your client organize their project and document workflows.',
      CREATE_PROJECT: 'Enable your client to create new projects.',
      SHARE_EXISTING_PROJECT: 'Share the projects you have already created.',
      BASIC_DOCUMENTS: 'Share your standalone documents.',
      DOCUMENTS: 'Share your documents.',
      SMART_DOCUMENTS: 'Share your smart documents (which automatically update repeating content across multiple documents at once).',
      SUMMARY: 'Share lay summaries of scientific articles.',
      COST: 'Share your project finances.',
      TRAINING: 'Allow your client to use Inclusive as a training platform for their teams, participants, and volunteers.',
      CREATE_NEW_TRAINING: 'Enable your client to create new training materials.',
      SHARE_EXISTING_TRAINING: 'Share the training materials you have already created.',
      EVENTS: 'Allow your client to create free, fundraising, or ticketed events.',
      FREE_EVENTS: 'Allow your client to create, manage and publicize free virtual and in-person events.',
      FUNDRAISER_EVENTS: 'Give your client access to fundraising and revenue tracking tools.',
      PAID_EVENTS: 'Allow your client to create paid virtual and in-person events, manage ticketing, and track their revenue.',
      DATA_MANAGEMENT: 'Enable your client to gather and visualize data that is relevant to their project using Inclusive’s tools.',
      ENGAGE: 'Grant your client the ability to create accessible surveys and share them with participants.',
      REPORTS: 'Share data collection and visualization tools with your client.',
      SOCIAL_MEDIA_TRENDS: 'Enable your client to gain insight into public opinion with real-time social data and web-based trends.',
      REQUEST_DATA_SERVICE: 'Enable your client to request a bespoke data generation consultancy service.',
      NETWORK_RESOURCES: 'Give your client access to a searchable database of research groups, events, forums, community groups, clinical trials, and informational resources.',
      EXPLORE_PARTICIPATION_ADVOCACY_NETWORK: 'Enable your client to find and join events and community groups.',
      COMMUNITY_GROUPS: 'Share access to a database of thousands of diverse community groups.',
      EVENTS_CAMPINGS: 'Grant access to a database of internal and external events and activities.',
      EXPLORE_RESEARCH_NETWORKS: 'Share access to a database of research groups, peer-reviewed studies, and clinical trials.',
      RESEARCH_GROUP: 'Grant access to a database of internal and external research groups and projects.',
      CLINICAL_TRIALS: 'Allow your client to search for active and recruiting clinical trials.',
      FORUM: 'Allow your client to search for aactive forums.',
      RESEARCH_ARTICLES: 'Allow your client to search a database of peer-reviewed studies and research articles.'
    };
  }

  public static INVITE_CSV_TEMPLATE = 'https://rgrid-public-files.s3.eu-west-2.amazonaws.com/help/invite_template.csv';
  public static COMMUNITY_RELATIONSHIP_CSV_TEMPLATE = 'https://rgrid-public-files.s3.eu-west-2.amazonaws.com/help/community_relationship_template.csv';

  public static TOOLTIP_DATE_FORMAT = 'DD MMM YYYY';

  public static COUNTRY_NUMBER_CODES = {
    AF: '+93',
    AL: '+355',
    DZ: '+213',
    AD: '+376',
    AO: '+244',
    AG: '+1-268',
    AR: '+54',
    AM: '+374',
    AU: '+61',
    AT: '+43',
    AZ: '+994',
    BS: '+1-242',
    BH: '+973',
    BD: '+880',
    BB: '+1-246',
    BY: '+375',
    BE: '+32',
    BZ: '+501',
    BJ: '+229',
    BT: '+975',
    BO: '+591',
    BA: '+387',
    BW: '+267',
    BR: '+55',
    BN: '+673',
    BG: '+359',
    BF: '+226',
    BI: '+257',
    CV: '+238',
    KH: '+855',
    CM: '+237',
    CA: '+1',
    CF: '+236',
    TD: '+235',
    CL: '+56',
    CN: '+86',
    CO: '+57',
    KM: '+269',
    CG: '+242',
    CD: '+243',
    CR: '+506',
    HR: '+385',
    CU: '+53',
    CY: '+357',
    CZ: '+420',
    DK: '+45',
    DJ: '+253',
    DM: '+1-767',
    DO: '+1-809',
    EC: '+593',
    EG: '+20',
    SV: '+503',
    GQ: '+240',
    ER: '+291',
    EE: '+372',
    ET: '+251',
    FJ: '+679',
    FI: '+358',
    FR: '+33',
    GA: '+241',
    GM: '+220',
    GE: '+995',
    DE: '+49',
    GH: '+233',
    GR: '+30',
    GD: '+1-473',
    GT: '+502',
    GN: '+224',
    GW: '+245',
    GY: '+592',
    HT: '+509',
    HN: '+504',
    HU: '+36',
    IS: '+354',
    IN: '+91',
    ID: '+62',
    IR: '+98',
    IQ: '+964',
    IE: '+353',
    IL: '+972',
    IT: '+39',
    JM: '+1-876',
    JP: '+81',
    JO: '+962',
    KZ: '+7',
    KE: '+254',
    KI: '+686',
    KP: '+850',
    KR: '+82',
    KW: '+965',
    KG: '+996',
    LA: '+856',
    LV: '+371',
    LB: '+961',
    LS: '+266',
    LR: '+231',
    LY: '+218',
    LI: '+423',
    LT: '+370',
    LU: '+352',
    MG: '+261',
    MW: '+265',
    MY: '+60',
    MV: '+960',
    ML: '+223',
    MT: '+356',
    MH: '+692',
    MR: '+222',
    MU: '+230',
    MX: '+52',
    FM: '+691',
    MD: '+373',
    MC: '+377',
    MN: '+976',
    ME: '+382',
    MA: '+212',
    MZ: '+258',
    MM: '+95',
    NA: '+264',
    NR: '+674',
    NP: '+977',
    NL: '+31',
    NZ: '+64',
    NI: '+505',
    NE: '+227',
    NG: '+234',
    NO: '+47',
    OM: '+968',
    PK: '+92',
    PW: '+680',
    PS: '+970',
    PA: '+507',
    PG: '+675',
    PY: '+595',
    PE: '+51',
    PH: '+63',
    PL: '+48',
    PT: '+351',
    QA: '+974',
    RO: '+40',
    RU: '+7',
    RW: '+250',
    KN: '+1-869',
    LC: '+1-758',
    VC: '+1-784',
    WS: '+685',
    SM: '+378',
    ST: '+239',
    SA: '+966',
    SN: '+221',
    RS: '+381',
    SC: '+248',
    SL: '+232',
    SG: '+65',
    SK: '+421',
    SI: '+386',
    SB: '+677',
    SO: '+252',
    ZA: '+27',
    SS: '+211',
    ES: '+34',
    LK: '+94',
    SD: '+249',
    SR: '+597',
    SZ: '+268',
    SE: '+46',
    CH: '+41',
    SY: '+963',
    TJ: '+992',
    TZ: '+255',
    TH: '+66',
    TL: '+670',
    TG: '+228',
    TO: '+676',
    TT: '+1-868',
    TN: '+216',
    TR: '+90',
    TM: '+993',
    TV: '+688',
    UG: '+256',
    UA: '+380',
    AE: '+971',
    GB: '+44',
    US: '+1',
    UY: '+598',
    UZ: '+998',
    VU: '+678',
    VE: '+58',
    VN: '+84',
    YE: '+967',
    ZM: '+260',
    ZW: '+263'
  };
}
