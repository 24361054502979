<div class="navbar display-flex">
  <img nolazyload class="cursor-pointer"
    src="{{ isRgrid ? '../../../assets/RG-Logo.svg' : '../../../assets/inclusive_health/inclusive_health_logo.png' }}"
    style="height: 2.5em;">
</div>
<div class="page-not-found">
  <div class="text-404">
    <img class="server-error" src="./assets/error/{{ isRgrid ? 'rgrid' : 'inclusive' }}/500-error.png" alt="">
  </div>
  <div class="description">
    <p>Whoops! Looks like something went wrong from our side. Please try reloading, after a while.</p>
  </div>
  <div class="home-screen-btn">
    <button class="font-size-15" mat-flat-button color="primary" (click)="routeToHomePage()">Reload</button>
  </div>
</div>
<div class="footer">
  <img nolazyload alt="rgrid logo" class="footer-image"
    src="{{ isRgrid ? '../../../assets/rgrid_logo_footer_icon.svg' : '../../../assets/inclusive_health/inclusive_health_footer.png' }}">
  <span _ngcontent-ntd-c376="" class="copyright">Copyright © 2024 Research Grid Ltd. All Rights Reserved</span>
</div>