/*
  * This service is used to create a user in mix-panel
  * Updated their actions using track() and this is done using the userId as the primary key.
*/
import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor(
    private storageService: StorageService
  ) { }
  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userId: string): void {
    mixpanel.init(environment.mixPanelToken);
    mixpanel.identify(userId);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  updateUserInfo(userInfo) {
    this.init(userInfo.id);
    mixpanel.people.set({
      $email: userInfo.email,   // only special properties need the $
      $created: new Date(),
      $last_login: new Date(),
      firstName: userInfo.firstName,
      name: userInfo.firstName,
      lastName: userInfo.lastName,
      id: userInfo.id       // feel free to define your own properties
    });
  }

  /**
   *
   * @param id
   * @param action
   * Functionality used to track the mixpanel track
   */
  createMixPanelTrack(id: string, action: any = {}) {
    if (!this.storageService.loggedUserDetails) {
      return;
    }
    const userDetails = this.storageService.loggedUserDetails;
    this.updateUserInfo(userDetails);
    this.track(id, action);
  }
}
