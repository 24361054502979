/*
  * This service is used to get, create, update and delete the group details.
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GroupService {
  public groupId: string;

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) { }

  private newGroupToTable = new Subject<boolean>();
  private msgCount = new Subject<boolean>();
  private newNotification = new Subject<boolean>();
  private allGroupsInfo = new Subject<any>();

  passGroupToTable = this.newGroupToTable.asObservable();
  passMsgCount = this.msgCount.asObservable();
  passGroupsInfo = this.allGroupsInfo.asObservable();
  passGroupMsgCountInfo = this.newNotification.asObservable();

  sendGroupToTable(value) {
    this.newGroupToTable.next(value);
  }

  sendAllGroupsInfo(groups) {
    this.allGroupsInfo.next(groups);
  }

  sendGroupsInfo(groupsInfo) {
    this.newNotification.next(groupsInfo);
  }

  sendMsgCount() {
    this.msgCount.next();
  }

  getAllGroups(userId, category) {
    return this.http.get(`${environment.baseUrl}api/group/?userId=${userId}&category=${category === 'Show all' ? '' : category}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getResearchersGroup(): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/client/groups`).toPromise();
  }

  getAllUsers() {
    return this.http.get(`${environment.baseUrl}api/group/users`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  createGroup(group: object) {
    return this.http.post(`${environment.baseUrl}api/group/`, group)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  public async addMemberToGroup(memberInfo: object) {
    try {
      return await this.http.post(`${environment.baseUrl}api/group/members`, memberInfo).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  public async updateGroupProjectId(groupId: string, projectId: string) {
    try {
      return await this.http.put(`${environment.baseUrl}api/group/update-trial`, { groupId, projectId }).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  public async downloadGroupChat(groupId: string) {
    try {
      return await this.http.get(`${environment.baseUrl}api/chat/download`, { params: { groupId } }).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  public async getGroupDetails(id: string): Promise<any> {
    try {
      return await this.http.get(`${environment.baseUrl}api/group/get-group`, { params: { id } }).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  updateGroupDetails(group) {
    return this.http.put(`${environment.baseUrl}api/group`, { group }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  toggleShareStatusGroup(group, userId) {
    return this.http.put(`${environment.baseUrl}api/group/share/status`, { groupInfo: group, userId }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  deleteGroup(groupId: string, userId) {
    return this.http.delete(`${environment.baseUrl}api/group?groupId=${groupId}&userId=${userId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  removeMemberByGroupId(deleteInfo) {
    return this.http.delete(`${environment.baseUrl}api/group/member?userId=${deleteInfo.userId}&groupId=${deleteInfo.groupId}&memberGroupId=${deleteInfo.memberGroupId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API to delete the multiple selected groups
  deleteSelectedGroups(groups) {
    return this.http.post(`${environment.baseUrl}api/group/delete`, { groups }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }


  // Hits the API to update the groups order
  updateGroupsOrder(groups) {
    return this.http.post(`${environment.baseUrl}api/group/order`, { groups }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API to get the invitation link for the group
  getGroupInviteLink(groupId) {
    return this.http.get(`${environment.baseUrl}api/group/invitation/link?groupId=${groupId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Used to update the group member notification count in the group
  updateNotificationCount(groupMemId, notificationCount) {
    return this.http.put(`${environment.baseUrl}api/group/notification/count`, { groupMemId, notificationCount }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API to update the anonymise the user status
  updateGroupAnonymiseStatus(groupId, anonymizedStatus) {
    return this.http.put(`${environment.baseUrl}api/group/anonymize`, { groupId, anonymizedStatus }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API to upload the file to invite members
  uploadFileToInviteMembers(file, groupId, customMessage) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('customMessage', customMessage);
    return this.http.post(`${environment.baseUrl}api/group/${groupId}/invite/file`, formData)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getRequestedMembers(groupId) {
    return this.http.get(`${environment.baseUrl}api/search-system/request?groupId=${groupId}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }


  // Hits the API to invite new member to group
  async inviteNewMemberToGroup(memberDetails) {
    try {
      return await this.http.post(`${environment.baseUrl}api/group/invite`, memberDetails).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  handleResponse(response: any) {
    return response;
  }
}
