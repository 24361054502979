import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { DashboardService } from '../services/dashboard.service';
import { catchError } from 'rxjs/operators';
import { ToasterService } from '../services/toaster.service';
import { MixpanelService } from '../services/mixPanel.service';
import { NavbarService } from '../services/navbar.service';
import { SettingsService } from '../services/settings.service';
import { SocketService } from '../services/socket.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private url: string;

    constructor(
        private authenticationService: AuthenticationService,
        private dashboardService: DashboardService,
        private toasterService: ToasterService,
        private mixpanelService: MixpanelService,
        private navbarService: NavbarService,
        private settingsService: SettingsService,
        private socketService: SocketService,
        private router: Router
    ) {
        this.router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                this.url = val.urlAfterRedirects || val.url || null;
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = { setHeaders: {}, url: req.url };
        if (req.url.indexOf('upload') < -1) {
            headers.setHeaders = { 'Content-Type': 'application/json' };
        }
        if (req.url.indexOf('s3.eu-') > -1) {
            return next.handle(req);
        }

        // Note: ProjectId is appended is to check do user have access for modules in Rgrid V2
        if (this.dashboardService.getProjectId()) {
            (headers.setHeaders as any)['projectId'] = this.dashboardService.getProjectId();
        }

        (headers.setHeaders as any)['timezone-offset'] = (String(new Date().getTimezoneOffset()));
        (headers.setHeaders as any)['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (this.authenticationService.hasToken) {
            (headers.setHeaders as any)['Authorization'] = this.authenticationService.hasToken ? this.authenticationService.token : '';
        }
        if (this.authenticationService.isLoggedIn) {
            (headers.setHeaders as any)['UserId'] = this.authenticationService.isLoggedIn ? this.authenticationService.userDetails.id : '';
        }
        req = req.clone(headers);
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {

                // If the system throws authentication error.
                try {
                    this.mixpanelService.createMixPanelTrack('Logged-Out', {});
                    this.navbarService.updateLogo.next({ isLoggedIn: false });
                    this.authenticationService.authenticationStatusUpdate.next({ isLoggedIn: false, msg: 'Unauthroised' });
                    this.settingsService.updateLoginStatus(this.authenticationService.user, false).subscribe((response) => { }, (err) => { });
                    this.socketService.setUserToOffline(this.authenticationService.user);
                    localStorage.clear();
                    this.authenticationService.endSession();
                    this.authenticationService.showTabs(false);
                    if (this.url) {
                        window.location.replace(`/login?callback=${encodeURIComponent(this.url)}`);
                    } else {
                        window.location.replace(`/login`);
                    }
                } catch (error) {
                    console.error('Auto logout system failed:', error);
                    localStorage.clear();
                    this.authenticationService.endSession();
                    this.authenticationService.showTabs(false);
                    window.location.replace(`/login`);
                }
            } else if (error.status === 503) {

                // If the service is not available
                console.error('Error! Service not available: ', error);
            } else if (error.status === 500) {

                // If the backend service fails
                console.error('Error! Backend service failed: ', error);
            } else {

                // All other errors
                console.error('Backend threw an error: ', error);
            }
            return throwError(error);
        }));
    }
}
