import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constants } from '../constants/constants';


@Injectable({
  providedIn: 'root'
})
export class IllustrationService {

  constructor() { }


  /**
   *
   * @param illustrationName  file name
   * @returns the illustration s3 URL
   * Service function used to form the illustration url based on the domain
   */
  getIllustrationUrl(illustrationName) {
    const title = environment.title === 'Rgrid' ? 'rgrid' : 'inclusive';
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}illustration/${title}/${illustrationName}`;
  }


  /**
   *
   * @param bannerName Banner name with the extension (EX - help.png)
   * @returns The complete banner public URL
   */
  getBannerImageURL(bannerName) {
    const title = environment.title === 'Rgrid' ? 'rgrid' : 'inclusive';
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}${title}/banner/${bannerName}`;
  }

  getPatientBannerImageURL(bannerName) {
    const title = environment.title === 'Rgrid' ? 'rgrid' : 'inclusive';
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}${title}/patient-banner/${bannerName}`;
  }

  /**
   *
   * @param bannerName Banner name with the extension (EX - engage.png)
   * @returns The complete landing banner public URL
   */
  getLandingBannerImageURL(bannerName) {
    const title = environment.title === 'Rgrid' ? 'rgrid' : 'inclusive';
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}${title}/landing/${bannerName}`;
  }

  /**
   *
   * @param imageName Image name stored in the s3
   * @returns Returns the image complete URL
   */
  getAppImageURL(imageName) {
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}landing_page/${imageName}`;
  }

  /**
   * Get images for the API pages.
   * @param imageName
   * @returns
   */
  getApiImageURL(imageName: string): string {
    const s3Domain = Constants.S3PUBLIC_DOMAIN;
    return `${s3Domain}api-page/${imageName}`;
  }

  /**
   * Get the assets which are in the project assets folder, either from the cdn service or from the project folder directly
   * @param path
   * @returns
   */
  getInProjectAssets(path: string): string {
    return `${environment.ASSET_CDN}${path}`;
  }

}
