import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CookiePolicyService {

  public cookiesList = [
    {
      id: 'essential',
      isActive: true,
      title: 'Essential cookies',
      // tslint:disable-next-line: max-line-length
      description: 'These are cookies that are required for the operation of our website, such as logging in. Without these cookies, you will not be able to use the system.',
      cookies: [ ]
    },
    {
      id: 'analytical',
      isActive: false,
      title: 'Analytics cookies',
      // tslint:disable-next-line: max-line-length
      description: 'These allow us to quantify visitors and how visitors move around our system. This helps us to improve the way our system works for you.',
      cookies: [ ]
    },
    {
      id: 'rejected',
      isActive: false,
      title: 'Reject all cookies',
      // tslint:disable-next-line: max-line-length
      description: 'If you select \'Reject all cookies\', we will not use cookies for additional purposes.',
      cookies: [ ]
    }
  ];

  private cookieDecline = false;
  private COOKIE_HANDLER: string;
  private COOKIE_NAME: string;
  private COOKIE_OPTIONS: {
    path: string,
    secure: boolean,
    sameSite: boolean | 'lax' | 'strict' | 'none',
    storeUnencoded: boolean;
    expires: string|Date,
  };
  public cookiePolicyUpdate = new Subject<{}>();

  constructor(
    private cookieService: CookieService
  ) {

    // Set the cookie name with version control
    this.COOKIE_HANDLER = `${environment.versionControl.env}_${environment.versionControl.v}`;
    this.COOKIE_NAME = `${this.COOKIE_HANDLER}_cookiePolicy`;

    // 1 Year Expiry session
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);


    // Setup the cookie options.
    this.COOKIE_OPTIONS = {
      path: '/',
      secure: environment.versionControl.secure,
      sameSite: environment.versionControl.sameSite as boolean | 'lax' | 'strict' | 'none',
      storeUnencoded: false,
      expires: expiryDate,
    };
  }

  public cookieUpdate = new Subject<{}>();

  public get hasCookiePolicy(): boolean {
    return !!(this.cookieService.get(this.COOKIE_NAME));
  }

  public get hasDeclinedPolicy(): boolean {
    return this.cookieDecline;
  }

  public acceptCookiePolicy(): void {
    const answer = this.cookiesList.map( cookie => {
      cookie.isActive = true;
      return cookie;
    });
    this.cookieService.put(this.COOKIE_NAME, JSON.stringify(answer), this.COOKIE_OPTIONS);
  }

  public acceptSelectedCookies(answer: any): void {
    this.cookieService.put(this.COOKIE_NAME, JSON.stringify(answer), this.COOKIE_OPTIONS);
  }

  public declineCookiePolicy(): void {
    this.cookieDecline = true;
    this.removeAllCookies();
  }

  public resetDecline(): void {
    this.cookieDecline = false;
  }

  public removeAllCookies(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.removeAll();
  }
}
