/*
  * This service is used to handle the error when the token is invalid.
*/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthenticationService } from './authentication.service';
import { SnackbarComponent } from '../ui/snackbar/snackbar.component';
import { Constants } from '../constants/constants';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})


export class HandleErrorService {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private storageService: StorageService
  ) { }

  handleError(error: any, isResolver: boolean = false): Promise<any> {
    try {
      if (error.error && error.error.message && error.error.message === Constants.MESSAGE.TOKEN_INVALID) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: Constants.SNACKBAR_TIMEOUT,
          verticalPosition: 'top',
          data: {
            message: Constants.MESSAGE.SESSION_EXPIRED
          }
        });
        this.storageService.clearStorage();
        this.authenticationService.showTabs(false);
        this.router.navigate(['login']);
      } else {
        if (isResolver) {
          this.router.navigate(['/404']);
        }
        return Promise.reject(error);
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
}
