import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../ui/snackbar/snackbar.component';
import { Constants } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

constructor(private snackBar: MatSnackBar) { }

  /**
   * Function used for displaying the alert message
   */
  showToaster(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
        duration: Constants.SNACKBAR_TIMEOUT,
        verticalPosition: 'top',
        panelClass: 'margin-snackbar',
        data: {
            message
        }
    });
  }

}
