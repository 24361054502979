export class User {
    createdAt: string;
    displayDocUploadInfo: boolean;
    displayGroupShareInfo: boolean;
    displayProjectShareInfo: boolean;
    email: string;
    salt: string;
    hashedPassword: string;
    failedLoginAttempts: number;
    firstName: string;
    forceLogout: boolean;
    fullName: string;
    hadCovid: boolean;
    hadCovidVaccine: boolean;
    id: string;
    interestedInResearch: boolean;
    isLoggedIn: boolean;
    isParticipant: boolean;
    isTempProfile: boolean;
    isTermsPrivacyAgreed: boolean;
    isToolTipActive: boolean;
    language: string;
    lastName: string;
    notificationsCount: number;
    onBoardHelpToolTipStatus: boolean;
    role: string;
    searchString: string;
    sharedStatus: string;
    status: string;
    updatedAt: string;
    profilePath: string;
    appLogo: string;
    selectedModule: string;
    paymentMethodId: string;
    stripeCustomerId: string;
    currency: string;
    participantAnonymization!: boolean;
    signUpRole!: string;
    signUpOrganization!: string;
    organizationId!: string;
    organization!: string;
    isPreferenceViaEmail!: boolean;
    isPreferenceViaSms!: boolean;
    buildingNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    phoneNumber: string;
    postalCode: string;
    userTheme!: any;
    defaultContactView!: string;
}
