import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  public isRgrid: boolean = environment.title === 'Rgrid';

  constructor(
    private router: Router
  ) {
    if (!this.isRgrid) {
      document.documentElement.style.setProperty('--primary-color', '#B4526a');
      document.documentElement.style.setProperty('--primary-color-dark', '#13423c');
      document.documentElement.style.setProperty('--primary-color-light', '#B4526a80');
      document.documentElement.style.setProperty('--primary-color-light-1', '#B4526a1a');
      document.documentElement.style.setProperty('--primary-color-light-2', '#B4526a05');
      document.documentElement.style.setProperty('--primary-color-light-3', '#B4526a03');
      document.documentElement.style.setProperty('--secondary-color', '#EEF0F2');
      document.documentElement.style.setProperty('--white', '#FFFFFF');
      document.documentElement.style.setProperty('--table-header', '#B4526a80');
      document.documentElement.style.setProperty('--table-header-bg', '#B4526a05');
      document.documentElement.style.setProperty('--search-border', '#B4526a');
      document.documentElement.style.setProperty('--search-bg', '3AB6A70F');
    }
  }

  ngOnInit(): void {
  }

  /**
   * Function used to route to the landing page.
   */
   routeToHomePage() {
    this.router.navigate(['/']);
  }

}
