/*
  * This service is used to get, create, update and delete the project details.
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  public projectId: string;
  public projectName: string;
  public toggleOnBoarding: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toggleProjectChange: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private authenticationService: AuthenticationService
  ) { }
  public dashBoardStatus = new Subject<boolean>();

  toggleDashboardStatus = this.dashBoardStatus.asObservable();

  isDashBoardRendered(isDashBoard) {
    this.dashBoardStatus.next(isDashBoard);
  }
  public setProjectId(id: string): void {
    this.projectId = id;
    localStorage.setItem('projectId', this.projectId);
  }

  public getProjectId(): string {
    return this.projectId || localStorage.getItem('projectId') || null;
  }

  public setProjectName(name: string): void {
    this.projectName = name;
    localStorage.setItem('projectName', this.projectName);
  }

  public getProjectName(): string {
    return this.projectName || localStorage.getItem('projectName') || null;
  }

  getAllProjects(userId) {
    const userDetails = this.authenticationService.userDetails;
    if (userDetails.role === 'Client') {
      return this.http.get(`${environment.baseUrl}api/client/projects`)
        .pipe(
          map(res => this.handleResponse(res)),
          catchError(err => this.handleErrorService.handleError(err))
        );
    } else {
      return this.http.get(`${environment.baseUrl}api/user-project?userId=${userId}`)
        .pipe(
          map(res => this.handleResponse(res)),
          catchError(err => this.handleErrorService.handleError(err))
        );
    }
  }

  getAllResearcherProjects(userId = ''): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/client/projects`).toPromise();
  }

  getAllUserProjects(): Promise<any> {
    try {
      return this.http.get(`${environment.baseUrl}api/user-project?userId=${this.authenticationService.userDetails.id}`).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }
  getAllDocContents(projectId) {
    return this.http.get(`${environment.baseUrl}api/user-project/docContents?projectId=${projectId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  updateDocFieldInContent(updatedDocInfo) {
    return this.http.put(`${environment.baseUrl}api/user-project/docContents`, updatedDocInfo)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }
  createNewProject(project: object): Promise<any> {
    return this.http.post(`${environment.baseUrl}api/user-project/`, project).toPromise();
  }

  async deleteProject(projectId: string) {
    try {
      return this.http.delete(`${environment.baseUrl}api/user-project?projectId=${projectId}`).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  updateShareProjectStatus(project) {
    return this.http.put(`${environment.baseUrl}api/user-project/share/status`, { project }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  updateProjectDetails(project) {
    return this.http.put(`${environment.baseUrl}api/user-project`, { project }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  /**
   * Hits the API to get the project enabled tabs
   */
  getProjectEnabledTabs() {
    return this.http.get(`${environment.baseUrl}api/user-project/tabs`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API and deletes the multiple projects
  async deleteMultipleProjects(projectIds) {
    try {
      return this.http.post(`${environment.baseUrl}api/user-project/bulk/delete`, { projectIds }).toPromise() as any;
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  handleResponse(response: any) {
    return response;
  }
}
