<div class="push-notification">
  <div fxLayout="row">
    <div class="profile cursor-pointer" *ngIf="data.notification.profilePic"><img
        class="profile-border border-radius-100" onerror="if (this.src != '../../../assets/common-profile.svg') this.src = '../../../assets/common-profile.svg';" NoLazyLoad width=40 height=40 [src]="data.notification.profilePic">
    </div>
    <div class="profile cursor-pointer" *ngIf="!data.notification.profilePic"><img
        class="profile-border border-radius-100" NoLazyLoad width=40 height=40 src="../../../assets/common-profile.svg">
    </div>
    <img class="small-logo" [src]="'../../../assets/' + logo">
    <div class="notification-header" fxLayout="column">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div *ngIf="data.type === 'Group'" class="name">
          {{ data.notification.displayName }} from {{ data.group.name }}
        </div>
        <div *ngIf="data.type === 'member-chat' || data.type === 'client-chat'" class="name">
          Message from {{ data.notification.displayName }}
        </div>
        <div *ngIf="data.type === 'group-join'" class="name">
          {{data.notification.requestedUser.firstName}} requested to join in your group
          {{data.notification.groupDetails.name}}
        </div>
      </div>
      <div class="sub-header" [innerHTML]="data.notification.message">
      </div>
    </div>
  </div>
</div>