/*
  * This service is used to update the user details.
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SettingsService {

  constructor(private http: HttpClient, private handleErrorService: HandleErrorService) { }

  private passCurrentUserInfo = new Subject<boolean>();
  passUserInfo = this.passCurrentUserInfo.asObservable();

  sendUserDetails(userInfo) {
    this.passCurrentUserInfo.next(userInfo);
  }
  updateProfilePicture(data) {
    return this.http.put(`${environment.baseUrl}api/user/picture`, { userDetails: data }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  removeUserProfilePicture(userId, profilePath) {
    return this.http.delete(`${environment.baseUrl}api/user/picture?userId=${userId}&profilePath=${profilePath}`).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateEmail(data) {
    return this.http.put(`${environment.baseUrl}api/user/email`, data).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateMemberSharedStatus(data) {
    return this.http.put(`${environment.baseUrl}api/user/sharedStatus`, data).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  changePassword(data) {
    return this.http.put(`${environment.baseUrl}api/user/password`, data).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  verifyPassword(oldPassword) {
    return this.http.post(`${environment.baseUrl}api/user/verify/password`, { oldPassword }, { observe: 'response' });
  }

  updateUserName(data) {
    return this.http.put(`${environment.baseUrl}api/user/name`, data).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateProfileDetails(userDetails) {
    return this.http.put(`${environment.baseUrl}api/user/`, userDetails).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updatePublicInfoToggle(userDetails) {
    return this.http.put(`${environment.baseUrl}api/user/displayInfo`, userDetails).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateLoginStatus(userId, isLogin) {
    return this.http.put(`${environment.baseUrl}api/user/loginStatus`, { userId, isLogin }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateNotificationsInfo(userId, notificationsInfo) {
    return this.http.put(`${environment.baseUrl}api/user/notifications`, { userId, notificationsInfo }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  sendFeedback(feedbackInfo) {
    return this.http.post(`${environment.baseUrl}api/user/feedback`, feedbackInfo).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  updateToolTipStatus(status) {
    return this.http.put(`${environment.baseUrl}api/tooltip/user/status`, { status }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  /**
   *
   * @param status
   * Function used to update the deactive status of the user
   */
  updateDeactiveStatus() {
    return this.http.put(`${environment.baseUrl}api/user/deactivate`, {}).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }


  /**
   *
   * @param status
   * Function used to update the delete status of the user and to remove all data from DB and cloud
   */
  updateDeleteStatus() {
    return this.http.put(`${environment.baseUrl}api/user/remove`, {}).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  // Hits the API to update the user's onboard  status
  updateOnboardToolTipStatus(status) {
    return this.http.put(`${environment.baseUrl}api/quick-tour/hide`, { status }).pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err)));
  }

  /**
   *
   * @param selectedFile
   * Hits the API to upload the user's logo to cloud
   */

  uploadUserLogo(selectedFile) {
    if (selectedFile !== '') {
      const formData = new FormData();
      formData.append('file', selectedFile);
      return this.http.post(`${environment.baseUrl}api/user/upload/logo`, formData)
        .pipe(
          map(res => this.handleResponse(res)),
          catchError(err => this.handleErrorService.handleError(err))
        );
    }
  }

  // Used to remove the user logo from the database and in the s3
  removeUserLogo() {
    return this.http.delete(`${environment.baseUrl}api/user/logo`)
    .pipe(
      map(res => this.handleResponse(res)),
      catchError(err => this.handleErrorService.handleError(err))
    );
  }

  getUserInvitedCount() {
    return this.http.get(`${environment.baseUrl}api/user/invitedCount`).toPromise();
  }

  checkCarerEmailExistsOrNot(carerEmail, patientId)  {
    return this.http.put(`${environment.baseUrl}api/carer/check`, { carerEmail, patientId}).toPromise();
  }

  /**
   *
   * @returns
   * Gets the subscription setting data
   */
  async getSubscriptionSettingData() {
    try {
      return this.http.get(`${environment.baseUrl}api/user/subscription/setting`).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  /**
   *
   * @param planId User Subscription Id
   * @returns
   * Hits to API to cancel the user subscription
   */
  async cancelUserSubscription(planId, reasons) {
    try {
      return this.http.put(`${environment.baseUrl}api/user/subscription/cancel`, { planId, cancelReason: reasons }).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

   /**
    *
    * @param planId User Subscription Id
    * @returns
    * Hits to API to resume the user subscription
    */
    async resumeUserSubscription(planId) {
      try {
        return this.http.put(`${environment.baseUrl}api/user/subscription/resume`, { planId }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    // Hits the APi to make payment as default
    async makePaymethodAsDefault(paymentMethod) {
      try {
        return this.http.put(`${environment.baseUrl}api/user/paymentmethods`, { paymentMethod }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    /**
     *
     * @param paymentMethod Payment Method Id created by stripe
     * @returns
     * Retuns the success message upon creating the payment
     */
    async createPaymentMethod(paymentMethod) {
      try {
        return this.http.post(`${environment.baseUrl}api/user/paymentmethods`, { paymentMethod }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    // Hits the API to delete the payment method
    async deletePaymentMethod(paymentMethod) {
      try {
        return this.http.delete(`${environment.baseUrl}api/user/paymentmethods?paymentMethod=${paymentMethod}`).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    // Hits the API and gets the compare result of the plans to update
    getComparedDetailsToChangePlan(planToUpdate): Promise<any> {
      try {
        return this.http.post(`${environment.baseUrl}api/user/subscription/compare`, { planToUpdate }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    // Hits the API to update the payment plan
    updatePaymentPlan(planId: string, currency: string = 'gbp'): Promise<any> {
      try {
        return this.http.put(`${environment.baseUrl}api/user/subscription/update`, { planId, currency }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }


    /**
     *
     * @param scheduledSubscriptionId Canceling Subscription Id
     * @returns
     * Hits the API to cancel the schduled subscription Id
     */
    cancelSchduledSubscription(scheduledSubscriptionId): Promise<any> {
      try {
        return this.http.put(`${environment.baseUrl}api/user/subscription/scheduled/cancel`, { scheduledSubscriptionId }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    /**
     *
     * @param feedbackDetails User feedback
     * @returns
     * Hits the API to create the new user feedback
     */
    createUserFeedback(feedbackDetails): Promise<any> {
      try {
        return this.http.post(`${environment.baseUrl}api/user/subscription/feedback`, feedbackDetails).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

    // Hist the API to get the suggestion module on cancelling the subscription
    getSugestingModule(planId): Promise<any> {
      try {
        return this.http.post(`${environment.baseUrl}api/user/subscription/suggest`, { planId }).toPromise();
      } catch (error) {
        return this.handleErrorService.handleError(error);
      }
    }

      // Hits the API and gets the lose module result of the plans to update
      getLoseModuleDetailsToChangePlan(currentPlanId, nextPlanId): Promise<any> {
        try {
          return this.http.post(`${environment.baseUrl}api/user/subscription/losemodules`, { currentPlanId, nextPlanId }).toPromise();
        } catch (error) {
          return this.handleErrorService.handleError(error);
        }
      }

      // Hits the API and gets the participant control access
      getTeamMemberParticipantControlAccess() {
        return this.http.get(`${environment.baseUrl}api/member/anonimization/users`).toPromise();
      }

      // Hits the API and updates the member anonymization status
      updateTeamMemberAnonymizationAccess(updateParams) {
        return this.http.put(`${environment.baseUrl}api/member/anonimization/access`, updateParams).toPromise();
      }
      // Hits the API to update the communication preference
      updateCommunicationPreference(isPreferenceViaEmail, isPreferenceViaSms) {
        return this.http.put(`${environment.baseUrl}api/user/communication`, {isPreferenceViaEmail, isPreferenceViaSms}).toPromise();
      }



    handleResponse(response: any) {
      return response;
    }
}
