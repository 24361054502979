import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

// tslint:disable-next-line: directive-selector
@Directive({ selector: 'img:not([NoLazyLoad])' })
export class LazyImgDirective implements AfterViewInit {

  /**
   * Do not load the images on start.
   */
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;

  constructor(
    private el: ElementRef
  ) {}

  /**
   * After the view is initilised, check if the image tag is in window view or not.
   */
  ngAfterViewInit(): any {
    this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  /**
   * Check if the tag is in view or not
   * @returns Boolean - If the tag is in view or not.
   */
  private canLazyLoad(): boolean {
    return window && 'IntersectionObserver' in window;
  }

  /**
   * If the image is in view, load the image.
   */
  private lazyLoadImage(): void {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  /**
   * If the image is not in view, do not load the image, just store the source tag.
   */
  private loadImage(): void {
    this.srcAttr = this.src;
  }
}
