/*
  * The authentication service is used to authenticate
    the user and create users
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie';
import { User } from '../models/user';
import { Carer } from '../models/carer';
import { CookiePolicyService } from './cookie-policy.service';
import { ApiResponse } from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Subscribe to authentication status change.
   */
  public authenticationStatusUpdate = new Subject<{}>();

  isUserParticipant = false;

  private showTabsInNav = new Subject<boolean>();
  private currentProfilePicture = new Subject<string>();
  private showSettingsInNav = new Subject<boolean>();
  private tempToken: string;

  private COOKIE_HANDLER: string;
  private COOKIE_OPTIONS: {
    path: string,
    secure: boolean,
    sameSite: boolean | 'lax' | 'strict' | 'none',
    storeUnencoded: boolean;
    expires: string | Date
  };

  showTabs$ = this.showTabsInNav.asObservable();
  showSettings$ = this.showSettingsInNav.asObservable();
  setProfilePicture$ = this.currentProfilePicture.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private cookiePolicyService: CookiePolicyService
  ) {
    // 24 hours cookie expiry
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Set the cookie name with version control
    this.COOKIE_HANDLER = `${environment.versionControl.env}_${environment.versionControl.v}`;

    // Setup the cookie options.
    this.COOKIE_OPTIONS = {
      path: '/',
      secure: environment.versionControl.secure,
      sameSite: environment.versionControl.sameSite as boolean | 'lax' | 'strict' | 'none',
      storeUnencoded: false,
      expires: tomorrow,
    };
  }

  /**
   * Check if the user is logged in.
   */
  public get isLoggedIn(): boolean {
    // tslint:disable-next-line: max-line-length
    return (this.cookieService.get(this.COOKIE_HANDLER + '_user') && this.cookieService.get(this.COOKIE_HANDLER + '_user') !== 'undefined') && (this.cookieService.get(this.COOKIE_HANDLER + '_session') && this.cookieService.get(this.COOKIE_HANDLER + '_session') !== 'undefined') ? true : false;
  }

  public get hasToken(): boolean {
    // tslint:disable-next-line: max-line-length
    return (this.cookieService.get(this.COOKIE_HANDLER + '_session') && this.cookieService.get(this.COOKIE_HANDLER + '_session') !== 'undefined') ? true : false;
  }

  /**
   * User ID stored in the cookie.
   */
  public get user(): string {
    if (this.isLoggedIn) {
      const user = JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_user'))) as User;
      return user.id as string;
    } else {
      return null;
    }
  }

  /**
   * User token stored in the cookie.
   */
  public get token(): string {
    if (this.isLoggedIn) {
      return this.cookieService.get(this.COOKIE_HANDLER + '_session') as string;
    } else {
      return null;
    }
  }

  /**
   * User participant status stored in the cookie.
   */
  public get isParticipant(): boolean {
    if (this.isLoggedIn) {
      const user = JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_user'))) as User;
      this.setIsParticipant(user.isParticipant);
      return user.isParticipant as boolean;
    } else {
      return false;
    }
  }

  /**
   * User details stored in the cookie.
   */
  public get userDetails(): User {
    if (this.isLoggedIn) {
      return JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_user'))) as User;
    } else {
      return null;
    }
  }

  /**
   * Carer details stored in the cookie.
   */
  public get carerDetails(): Carer {
    // tslint:disable-next-line: max-line-length
    if (this.cookieService.get(this.COOKIE_HANDLER + '_carer') && this.cookieService.get(this.COOKIE_HANDLER + '_carer') !== 'undefined') {
      return JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_carer'))).carer as Carer;
    } else {
      return new Carer();
    }
  }

  /**
   * Carer details stored in the cookie.
   */
  public get isCarer(): boolean {
    // tslint:disable-next-line: max-line-length
    if (this.cookieService.get(this.COOKIE_HANDLER + '_carer') && this.cookieService.get(this.COOKIE_HANDLER + '_carer') !== 'undefined') {
      return JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_carer'))).isCarer as boolean;
    } else {
      return false;
    }
  }

  public checkIsParticipant(): boolean {
    return this.userDetails.isParticipant;
  }

  /**
   * Save the user object in the cookie.
   * @param user user object from API
   * @returns user object
   */
  public saveUser(user: User, force = false): User {
    if (force || this.cookiePolicyService.hasCookiePolicy) {
      user.hashedPassword ? delete user.hashedPassword : null;
      user.salt ? delete user.salt : null;
      user.updatedAt ? delete user.updatedAt : null;
      user.forceLogout ? delete user.forceLogout : null;
      user['integrations'] ? delete user['integrations'] : null;
      this.cookieService.put(this.COOKIE_HANDLER + '_user', JSON.stringify(user), this.COOKIE_OPTIONS);
      return user;
    } else {
      return new User();
    }
  }

  /**
   * Save the carer object in the cookie.
   * @param user user object from API
   * @returns user object
   */
  public saveCarer(isCarer: boolean, carer: Carer = new Carer()): Carer {
    if (this.cookiePolicyService.hasCookiePolicy) {
      this.cookieService.put(this.COOKIE_HANDLER + '_carer', JSON.stringify({ isCarer, carer }), this.COOKIE_OPTIONS);
      return carer;
    } else {
      return new Carer();
    }
  }

  /**
   * Save the user session token.
   * @param token User session token
   * @returns user session token
   */
  public saveToken(token: string, force = false): string {
    if (force || this.cookiePolicyService.hasCookiePolicy) {
      this.cookieService.put(this.COOKIE_HANDLER + '_session', token, this.COOKIE_OPTIONS);
      return token;
    } else {
      return '';
    }
  }


  /**
   * Saves the client pending quote status and used it in the middleware
   * @param quoteId
   * @param status
   */
  public saveClientQuoteDetails(quoteId: string, status: string, researcherId: string) {
    this.cookieService.put(this.COOKIE_HANDLER + '_quoteStatus', JSON.stringify({ quoteId, status, researcherId }), this.COOKIE_OPTIONS);
  }

  public get clientQuoteDetails() {
    const quote = this.cookieService.get(this.COOKIE_HANDLER + '_quoteStatus');
    if (!quote) {
      return '';
    }
    return JSON.parse(decodeURIComponent(this.cookieService.get(this.COOKIE_HANDLER + '_quoteStatus')));
  }

  public saveTempToken(token: string): void {
    this.tempToken = token;
  }

  public getTempToken(): string {
    return this.tempToken;
  }

  public set enabledTabs(tabs: any) {
    sessionStorage.removeItem(this.COOKIE_HANDLER + '_enabledTabs');
    sessionStorage.setItem(this.COOKIE_HANDLER + '_enabledTabs', encodeURIComponent(JSON.stringify(tabs)));
  }

  public get enabledTabs(): any {
    // tslint:disable-next-line: max-line-length
    if (sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs') && sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs') !== 'undefined' && sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs') !== null) {
      if ((typeof JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs')))) === 'string') {
        return JSON.parse(JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs'))));
      }
      return JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_enabledTabs'))) as any;
    } else {
      return {};
    }
  }

  /**
   * Delete the user session cookies.
   */
  public async endSession(): Promise<void> {
    this.cookieService.remove(this.COOKIE_HANDLER + '_design');
    this.cookieService.remove(this.COOKIE_HANDLER + '_user');
    this.cookieService.remove(this.COOKIE_HANDLER + '_session');
    this.cookieService.remove(this.COOKIE_HANDLER + '_carer');
    sessionStorage.removeItem(this.COOKIE_HANDLER + '_pricing');
    sessionStorage.removeItem(this.COOKIE_HANDLER + '_enabledTabs');
    this.cookieService.remove(this.COOKIE_HANDLER + '_quoteStatus');
  }

  public removeQuote() {
    this.cookieService.remove(this.COOKIE_HANDLER + '_quoteStatus');
  }

  /**
   * Save the user object in the cookie.
   * @param user user object from API
   * @returns user object
   */
  public updateUser(user: User): User {
    this.cookieService.remove(this.COOKIE_HANDLER + '_user');
    user.hashedPassword ? delete user.hashedPassword : null;
    user.salt ? delete user.salt : null;
    user.updatedAt ? delete user.updatedAt : null;
    user.forceLogout ? delete user.forceLogout : null;
    user['integrations'] ? delete user['integrations'] : null;
    this.cookieService.put(this.COOKIE_HANDLER + '_user', JSON.stringify(user), this.COOKIE_OPTIONS);
    return user;
  }

  /**
   * Updates the pricing details
   * @returns Return pricing
   */
  public updatePricingDetails(pricing: any) {
    sessionStorage.removeItem(this.COOKIE_HANDLER + '_pricing');
    this.cookieService.remove(this.COOKIE_HANDLER + '_design');
    sessionStorage.setItem(this.COOKIE_HANDLER + '_pricing', encodeURIComponent(JSON.stringify(pricing)));
    const pricingJson = JSON.parse(pricing);
    this.cookieService.put(this.COOKIE_HANDLER + '_design', JSON.stringify({ pricePlan: { designType: pricingJson && pricingJson.pricePlan && pricingJson.pricePlan.designType ? pricingJson.pricePlan.designType : 0 } }), this.COOKIE_OPTIONS);
    return pricing;
  }

  /**
   * Clears the pricing details
   * @returns Return pricing
   */
  public clearPricingDetails() {
    sessionStorage.removeItem(this.COOKIE_HANDLER + '_pricing');
    this.cookieService.remove(this.COOKIE_HANDLER + '_design');
  }

  /**
   * Get user pricing details
   */
  public get pricingDetails(): any {
    // tslint:disable-next-line: max-line-length
    if (sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing') && sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing') !== 'undefined' && sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing') !== null) {
      if ((typeof JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing')))) === 'string') {
        return JSON.parse(JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing'))));
      }
      return JSON.parse(decodeURIComponent(sessionStorage.getItem(this.COOKIE_HANDLER + '_pricing')));
    } else {
      return false;
    }
  }

  showTabs(val) {
    this.showTabsInNav.next(val);
  }

  showSettings(val) {
    this.showSettingsInNav.next(val);
  }
  
  setIsParticipant(isUserParticipant) {
    this.isUserParticipant = isUserParticipant;
  }

  setProfilePicture(path) {
    this.currentProfilePicture.next(path);
  }

  getNewUserId() {
    return this.http.get(`${environment.baseUrl}api/user/newId`).toPromise();
  }

  authenticateUser(user: object) {
    return this.http.post(`${environment.baseUrl}authenticate`, user)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  createUser(user: object): Promise<any> {
    return this.http.post(`${environment.baseUrl}fresh-onboard`, user).toPromise();
  }


  async createProfile(user: object) {
    return await this.http.post(`${environment.baseUrl}fresh-onboard/profile`, user).toPromise();
  }

  getAccess(data) {
    return this.http.post(`${environment.baseUrl}access`, data)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  getReset(data) {
    return this.http.post(`${environment.baseUrl}get-reset`, data)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  resetPassword(data) {
    return this.http.post(`${environment.baseUrl}reset-password`, data)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  /**
   *
   * @param email Email that needs to trigger the confirmation mail
   * The service function is used to resend the confirmation mail to the email
   */
  resendConfirmationMail(email) {
    return this.http.put(`${environment.baseUrl}fresh-onboard/resend`, { email })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  /**
   *
   * @param email - Email of the user
   * @param password - Password of the user
   * Hits the API to check the password matches with the old one or not
   */
  checkPasswordMatchesOrNot(email, password) {
    return this.http.post(`${environment.baseUrl}api/reset/check`, { email, password })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleError(err))
      );
  }

  rgridPricingModules(): Promise<ApiResponse> {
    return this.http.get(`${environment.baseUrl}fresh-onboard/module`).toPromise() as Promise<ApiResponse>;
  }

  getSignedURL(key: string): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/question-polls/signedurl?key=${key}`).toPromise();
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  handleResponse(response: any) {
    return response;
  }
}
