import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

declare const mApp: any;
@NgModule()
export class Utils {

  /**
   * Show loader on an element
   * @param element element selector e.g. #div-id
   */
  public static showLoader(element: string): void {
    mApp.showLoader(element);
  }

  /**
   * Hide loader on an element
   * @param element element selector e.g. #div-id
   */
  public static hideLoader(element: string): void {
    mApp.hideLoader(element);
  }

  /**
   * Show loader on entire page
   */
  public static blockPage(): void {
    mApp.blockPage();
  }

  /**
   * Hide loader from the page
   */
  public static unblockPage(): void {
    mApp.unblockPage();
  }

  /**
   * Check if the user is participant
   * @returns boolean
   */
  public static isParticipant(): boolean {
    try {
      function getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

      const cookieName = `${environment.versionControl.env}_${environment.versionControl.v}_user`;
      const user = getCookie(cookieName) && getCookie(cookieName).length ? JSON.parse(decodeURIComponent(getCookie(cookieName))) : { isParticipant: false };
      return user.isParticipant;
    } catch (error) {
      return false;
    }
  }

  /**
   * Check if the user is participant
   * @returns boolean
   */
  public static isCharityOrganisation(): boolean {
    try {
      function getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

      const cookieName = `${environment.versionControl.env}_${environment.versionControl.v}_user`;
      const user = getCookie(cookieName) && getCookie(cookieName).length ? JSON.parse(decodeURIComponent(getCookie(cookieName))) : { organizationId: false };
      return !!(user.organizationId);
    } catch (error) {
      return false;
    }
  }

  public static isInclusive(): boolean {
    return environment.title === 'Inclusive';
  }

  /**
   * Get pricing object
   * @returns
   */
  public static getPricingSessions(): any {
    try {
      const cookieName = `${environment.versionControl.env}_${environment.versionControl.v}_pricing`;
      const pricingPlan = sessionStorage.getItem(cookieName) && sessionStorage.getItem(cookieName).length ? JSON.parse(decodeURIComponent(sessionStorage.getItem(cookieName))) : {};
      return JSON.parse(pricingPlan);
    } catch (error) {
      return {};
    }
  }

  public static getDesignCookie(): any {
    try {
      function getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

      const cookieName = `${environment.versionControl.env}_${environment.versionControl.v}_design`;
      const pricingPlan = getCookie(cookieName) && getCookie(cookieName).length ? JSON.parse(decodeURIComponent(getCookie(cookieName))) : {};
      return pricingPlan;
    } catch (error) {
      return {};
    }
  }

  /**
  * Get pricing object
  * @returns
  */
  public static getPricingCookie(): any {
    try {
      function getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      }

      const cookieName = `${environment.versionControl.env}_${environment.versionControl.v}_pricing`;
      const pricingPlan = sessionStorage.getItem(cookieName) && sessionStorage.getItem(cookieName).length ? JSON.parse(decodeURIComponent(sessionStorage.getItem(cookieName))) : {};
      return JSON.parse(pricingPlan);
    } catch (error) {
      return {};
    }
  }

  public static dynamicColors() {
    const singleBar = {
      backgroundColor: '',
      borderColor: ''
    };
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    singleBar.backgroundColor = 'rgba(' + r + ',' + g + ',' + b + ', 0.1)';
    singleBar.borderColor = 'rgba(' + r + ',' + g + ',' + b + ', 0.8)';
    return singleBar;
  }

  public static poolColors(a) {
    const colors = {
      backgroundColor: [],
      borderColor: []
    };
    for (let i = 0; i < a; i++) {
      const bar = this.dynamicColors();
      colors.backgroundColor.push(bar.backgroundColor);
      colors.borderColor.push(bar.borderColor);
    }
    return colors;
  }

  public static copyToClipBoard(text) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static numberWithComma = (number) => number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  public static editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = new Array();
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i == 0) {
          costs[j] = j;
        } else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            }
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) {
        costs[s2.length] = lastValue;
      }
    }
    return costs[s2.length];
  }
}
