import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import lodash from 'lodash';

import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { Constants } from '../constants/constants';
import { RgridPricingModule } from '../models/rgrid-pricing/rgrid-pricing-module';


const getRgridModule = (module) => {
  if (!module.children || !module.children.length) {
    return module;
  }
  return [module, lodash.flatMapDeep(module.children, getRgridModule)];
};

@Injectable({
  providedIn: 'root'
})

export class PricingService {

  public updatePlan: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private handleErrorService: HandleErrorService, private http: HttpClient,
              private storageService: StorageService, private router: Router) { }


  getNoAccessErrorMessage() {
    return this.storageService.loggedUserDetails.role === 'Client' ? Constants.MESSAGE.CLIENT_NO_ACCESS : Constants.MESSAGE.UPGRADE_PLAN;
  }

  public isBuilderPlan() {
    const pricingDetails = this.storageService.pricingDetails;
    return pricingDetails?.pricePlan?.moduleName === 'Builder';
  }

  /**
   *
   * @param moduleName Module Name
   * @returns
   * Functionality used to form the object to get module access
   */
  getModuleAccess(moduleName) {
    const pricingDetails = this.storageService.pricingDetails;
    if (pricingDetails) {
      if (environment.title === 'Rgrid') {
        return {
          allModuleAccess: pricingDetails ? pricingDetails.allModuleAccess : false,
          isInvited: pricingDetails.isInvited ? pricingDetails.isInvited : false,
          [moduleName]: {
            ...pricingDetails[moduleName],
            read: (pricingDetails[moduleName] && pricingDetails[moduleName].read) || true,
            write: (pricingDetails[moduleName] && pricingDetails[moduleName].write) ||
              (this.storageService.loggedUserDetails.role === 'Owner' || this.storageService.loggedUserDetails.role === 'Admin' ||
                this.storageService.loggedUserDetails.role === 'Team Member') || false
          }
        };
      } else {
        return {
          allModuleAccess: pricingDetails ? pricingDetails.allModuleAccess : false,
          isInvited: pricingDetails.isInvited ? pricingDetails.isInvited : false,
          [moduleName]: { ...pricingDetails[moduleName] }
        };
      }
    } else {
      return {
        allModuleAccess: false,
        [moduleName]: {}
      };
    }

  }

  // Hits the API to get the whole app module access
  getAppModuleAccess() {
    const userToken = this.storageService.getKeyValue('token');
    if (userToken) {
      return this.http.get(`${environment.baseUrl}api/user/app/access`)
        .pipe(
          map(res => this.handleResponse(res)),
          catchError(err => this.handleErrorService.handleError(err))
        );
    }
  }

  // Hits the API to get the selected module details from the api
  getSelectedModulePricingDetails(moduleId) {
    return this.http.get(`${environment.baseUrl}api/pricing/user/last?moduleId=${moduleId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  public async getModulePricingDetails(moduleId: string): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/pricing/user/last?moduleId=${moduleId}`).toPromise() as Promise<any>;
  }

  /**
   *
   * @returns
   * Hits the API to make the payment
   */

  makePaymentForSelectedModule(moduleId, paymentId, billingDetails, planId, systemPlanId, promoCode = null, currency) {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${environment.baseUrl}api/pricing/payment`, { moduleId, paymentId, billingInfo: billingDetails, planId, promoCode, systemPlanId, currency })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  /**
   *
   * @param requestData Request Data from from
   * @returns
   * Hits the API to request the customized version
   */
  async contactAdminForCustomizedVersion(requestData) {
    try {
      return await this.http.post(`${environment.baseUrl}api/pricing/cutomized/request`, requestData).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  // Hits the API and gets the pricing session details
  async getPricingSessionDetails(sessionId) {
    try {
      return await this.http.get(`${environment.baseUrl}api/pricing/session/${sessionId}`).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  async getUserPaymentSetupIntent(): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/pricing/user/setupintent`).toPromise();
  }

  formPricingJson(pricingModules) {
    const allHeaders = pricingModules.map(pricingModule => {

      return lodash.omit(pricingModule, ['accessLevels']);
    });
    const headers = [].concat([{ moduleName: 'Features', id: 'default' }], allHeaders);

    const defaultModuleAccess = pricingModules[0].accessLevels;

    const resultArr = [];

    let moduleKeyIndex = 0;
    for (const module of defaultModuleAccess) {
      const resObj = {};
      let headerIndex = 1;
      for (const header of headers) {
        if (header.id === 'default') {
          resObj['key'] = module.key;
        } else {
          if (pricingModules[headerIndex - 2]) {
            const accessData = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex];
            resObj[pricingModules[headerIndex - 2].id] = accessData.value || accessData.haveAccess;
            resObj['isSubModule'] = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex].isSubModule || false;
            resObj['level'] = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex].level || 1;
            resObj['desc'] = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex].desc || '';
            resObj[pricingModules[headerIndex - 2].id + '_showHyphen'] = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex].showHyphen || false;
            resObj[pricingModules[headerIndex - 2].id + '_showAddon'] = pricingModules[headerIndex - 2].accessLevels[moduleKeyIndex].isAddonAvailable || false;
          }

        }
        headerIndex++;
      }
      moduleKeyIndex++;
      resultArr.push(resObj);
    }
    return { resultArr, headers };
  }


  deepFlattenRgridModules(modules) {
    return lodash.flatMapDeep(modules, getRgridModule);
  }

  clearPricingSignUpStorage() {
    this.storageService.removeSessionStorageKey('selectedModules');
    this.storageService.removeSessionStorageKey('signupUserDetails');
    this.storageService.removeSessionStorageKey('signupMessage');
  }

  public isChildSelected(parentModule: RgridPricingModule) {
    if (parentModule.children.length) {
      const isAnySelected = parentModule.children.some((childModule: RgridPricingModule) => {
        if (childModule.isChecked) {
          return true;
        }
        if (childModule.children?.length) {
          return this.isChildSelected(childModule);
        }
        return false;
      });
      return isAnySelected;
    }
    return true;
  }

  public updateAllChild(status: boolean, parentModule: RgridPricingModule) {
    parentModule.isChecked = status;
    if (parentModule.children.length) {
      parentModule.children = parentModule.children.map((childModule: RgridPricingModule) => {
        childModule.isChecked = status;
        if (childModule.children.length) {
          this.updateAllChild(status, childModule);
        }
        return childModule;
      });
    }
  }


  handleResponse(response: any) {
    return response;
  }
}
