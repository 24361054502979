import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { HandleErrorService } from './handle-error.service';
import { PushNotificationComponent } from '../ui/push-notification/push-notification.component';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public isGroupChatComponentRendered = false;

  constructor(
    private http: HttpClient,
    private handleErrorService: HandleErrorService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
  }

  /**
   * Setter function used to store the status of the group chat rendered or not
   * This can be used to controlled for the push notifications.
   */
  set groupChatStatus(status: boolean) {
    this.isGroupChatComponentRendered = status;
  }

  /**
   * Getting the group chat component rendered status
   */
  get groupChatStatus(): boolean {
    return this.isGroupChatComponentRendered;
  }

  sendChatToGroup(chatInfo, groupId) {
    return this.http.post(`${environment.baseUrl}api/chat/`, { chatInfo, groupId })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  uploadFile(file: any, groupChatId: string) {
    try {
      return this.http.post(`${environment.baseUrl}api/chat/upload?groupChatId=${groupChatId}`, file).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  uploadChatFileMultiChat(formData: FormData) {
    try {
      return this.http.post(`${environment.baseUrl}api/chat/upload/multiple`, formData).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  sendChatToForum(chatInfo, forumId) {
    return this.http.post(`${environment.baseUrl}api/forum-chat/`, { chatInfo, forumId })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  uploadFileToForum(file: any, forumChatId: string) {
    try {
      return this.http.post(`${environment.baseUrl}api/forum-chat/upload?groupChatId=${forumChatId}`, file).toPromise();
    } catch (error) {
      return this.handleErrorService.handleError(error);
    }
  }

  sendChatToMember(chatInfo, memberId) {
    return this.http.post(`${environment.baseUrl}api/single-chat/`, { chatInfo, memberId })
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getAllChatsOfMember(contactId) {
    return this.http.get(`${environment.baseUrl}api/single-chat/?contactId=${contactId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getAllChatsByGroupId(groupId) {
    return this.http.get(`${environment.baseUrl}api/chat/?groupId=${groupId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getAllChatsByForumId(forumId) {
    return this.http.get(`${environment.baseUrl}api/forum-chat/?forumId=${forumId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getUsersByCategory(category) {
    return this.http.get(`${environment.baseUrl}api/group/users/?category=${category}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  getForumUsersByCategory(category) {
    return this.http.get(`${environment.baseUrl}api/forum/users/?category=${category}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  handleResponse(response: any) {
    return response;
  }


  public downloadChat(contactId: string, otherUserId: string): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/single-chat/download`, { params: { contactId, otherUserId } }).toPromise();
  }


  /**
   *
   * @param groupInfo
   * @param type
   * @param param2
   * Fucntion is used to show the push notifications on receiving the new messages.
   */
  showNotification(groupInfo, type, [chat]) {
    if (this.authenticationService.isLoggedIn) {
      this.snackBar.openFromComponent(PushNotificationComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        data: {
          notification: chat,
          group: groupInfo,
          type
        }
      });
    }
  }

  // Hits the API to delete the group chat
  deleteGroupChat(chartId) {
    return this.http.delete(`${environment.baseUrl}api/chat/?chatId=${chartId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  // Hits the API to delete the group chat
  deleteForumChat(chartId) {
    return this.http.delete(`${environment.baseUrl}api/forum-chat/?chatId=${chartId}`)
      .pipe(
        map(res => this.handleResponse(res)),
        catchError(err => this.handleErrorService.handleError(err))
      );
  }

  sendSchduledMessage(eventParams): Promise<any> {
    return this.http.post(`${environment.baseUrl}api/schduled-messages`, eventParams).toPromise();
  }

  getSchduledMessageData(schduleId: string): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/schduled-messages/${schduleId}`).toPromise();
  }

  updateSchduleMessage(schduleId, updateParams): Promise<any> {
    return this.http.put(`${environment.baseUrl}api/schduled-messages`, { id: schduleId, ...updateParams }).toPromise();
  }

  getURLMetaData(url: string): Promise<any> {
    return this.http.post(`${environment.baseUrl}api/util/scrape/metadata`, { url }).toPromise();
  }

  deleteURLMetaData(chatId: string, from: string): Promise<any> {
    return this.http.delete(`${environment.baseUrl}api/${from}/preview/?chatId=${chatId}`).toPromise();
  }

  voteDiscussion(voteInfo: any, discussionId: string): Promise<any> {
    return this.http.post(`${environment.baseUrl}api/forum-chat/${discussionId}/vote`, voteInfo).toPromise();
  }

  sendReply(chatInfo, forumId): Promise<any> {
    return this.http.post(`${environment.baseUrl}api/forum-chat/reply`, { chatInfo, forumId }).toPromise();
  }

  getReplyList(forumId, chatId): Promise<any> {
    return this.http.get(`${environment.baseUrl}api/forum-chat/reply/${chatId}/${forumId}`).toPromise();
  }

  deleteFile(forumId, fileName): Promise<any> {
    return this.http.delete(`${environment.baseUrl}api/forum-chat/delete/${forumId}?fileName=${fileName}`).toPromise();
  }

  updateChatDetails(chatId, chatInfo): Promise<any> {
    return this.http.put(`${environment.baseUrl}api/forum-chat/chat`, { chatId, chatInfo }).toPromise();
  }

  updateReplyDetails(chatId, chatInfo): Promise<any> {
    return this.http.put(`${environment.baseUrl}api/forum-chat/reply/${chatId}`, { chatId, chatInfo }).toPromise();
  }

  deleteReply(chatId): Promise<any> {
    return this.http.delete(`${environment.baseUrl}api/forum-chat/reply/delete?chatId=${chatId}`).toPromise();
  }
}
