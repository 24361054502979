<div class="height-100-percent">
    <div *ngIf="showScrolling" class="scroll-view">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="height-100-percent">
            <div>
                <img [src]="getInProjectAssets('scroll-icon.svg')" alt="">
            </div>
            <div>
                <div class="scroll-page-description my-20">
                    <div>
                        Please hover over the area/accordians/sub-sections and use
                        
                    </div>
                    <div>
                        <span class="scroll-highlight">Shift + Scroll</span> to <span>scroll horizontally</span>
                    </div>
                    <!-- <div>
                        <span>scroll horizontally</span>
                    </div> -->
                </div>
                <div class="my-20">
                    <span class="got-it-button cursor-pointer" (click)="updateGotItStatus($event)">Got it</span>
                </div>
            </div>
        </div>
    </div>
    <div class="height-100-percent">
        <router-outlet></router-outlet>
    </div>
</div>