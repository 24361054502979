import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public loggedUserSubscriber: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public updateScrollView: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public updateScrollViewStatus: BehaviorSubject<any> = new BehaviorSubject<any>({});


  public loggedUser: any = {
    firstName: '',
    profilePath: ''
  };

  public appEnabledTabs = {};
  appStorage = window.sessionStorage;

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  /**
   * Setter and getter methods used to get the logged user details
   */
  public set loggedUserDetails(user: any) {
    this.loggedUser = user;
  }

  public get loggedUserDetails(): any {
    return this.authenticationService.userDetails;
  }

  /**
   * Functionality is used to get the participant details from the localstorage
   */
  public isParticipant(): any {
    return this.authenticationService.isParticipant;
  }


  /**
   * Function used to return the userid fetching from the localstorage.
   */
  public getUserId(): any {
    return this.authenticationService.user;
  }

  /**
   * Functionality is used to get the project Id from the localstorage
   */
  public getProjectId(): any {
    return localStorage.getItem('projectId');
  }

  public getProjectName(): any {
    return localStorage.getItem('title');
  }

  /**
   *
   * @param key - the name of the cookie
   * @param value - the value in the cookie
   * Function used to set the localstorage key value pairs
   */
  public setKey(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   *
   * @param key
   * Function wull get the key value from localstorage
   */
  public getKeyValue(key: any): any {
    switch (key) {
      case 'token':
        return this.authenticationService.token;
      case 'user':
        return this.authenticationService.user;
      case 'isParticipant':
        return this.authenticationService.isParticipant;
      default:
        return localStorage.getItem(key);
    }
  }

  /**
   *
   * @param key
   * Functionality is used to remove item from localstorage
   */
  public removeItem(key) {
    localStorage.removeItem(key);
  }
  /**
   * Function will all the localstorage
   */
  public clearStorage() {
    localStorage.clear();
    this.appStorage.clear();
    this.authenticationService.endSession();
  }

  // Function will remove the session storage elements
  public removeSessionStorageKey(key) {
    if (key === 'pricingDetails') {
      this.authenticationService.clearPricingDetails();
    }
    this.appStorage.removeItem(key);
  }

  public set pricingDetails(details) {
    this.authenticationService.updatePricingDetails(details);
  }

  public get pricingDetails(): any {
    const pricingDetails = this.authenticationService.pricingDetails;
    if (pricingDetails) {
      return pricingDetails;
    }
    return null;
  }

  public clearSessionStorage() {
    this.appStorage.clear();
  }

  public setSessionStorage(key, value) {
    this.appStorage.setItem(key, value);
  }

  public getSessionStorage(key) {
    return this.appStorage.getItem(key);
  }

  public get enabledTabs(): any {
    return this.authenticationService.enabledTabs;
  }

  public set enabledTabs(enabledTabs: any) {
    this.authenticationService.enabledTabs = enabledTabs;
  }
}
