/*
  * This component is used to show a toaster/snackbar with the
  * particular message that is passed to it.
*/

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {

  /**
   *
   * @param data Mat Snackbar reference
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  /**
	 * Fucntion will be invoked on the component intializing
	 */
  ngOnInit() {
  }

}
